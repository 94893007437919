<template>
  <SlideBase forward back :single-pane="true">
    <template #left>
      <div class="gap-y-8 lg:px-4 flex-col md:flex-grow flex justify-center">
        <h1>Share your socials (optional)</h1>
        <div>
          <p class="font-monument pb-2">
            The whole report is anonymous, and we never publish names, emails, or phone numbers.
          </p>
          <p class="font-monument">
            However, many witnesses do request to be credited for their sightings. If you want to be credited, share your social handle(s) below.
          </p>
        </div>
        <div class="flex flex-col gap-y-8 w-full">
          <input
            id="twitterInput"
            ref="twitterInputElement"
            v-model="twitterInput"
            type="text"
            class="
                social
                social-twitter
                form-control
                block
                px-3
                py-1.5
                text-base
                font-normal
                bg-transparent
                bg-clip-padding
                border-0
                border-b border-solid border-gray-300
                transition
                ease-in-out
                focus:ring-0
                m-0
                focus:border-space-orange focus:outline-none
              "
            placeholder="@Twitter / @X"
          >
          <input
            id="instagramInput"
            ref="instagramInputElement"
            v-model="instagramInput"
            type="text"
            class="
                social
                social-insta
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                bg-transparent
                bg-clip-padding
                border-0
                border-b border-solid border-gray-300
                transition
                ease-in-out
                focus:ring-0
                m-0
                focus:border-space-orange focus:outline-none
              "
            placeholder="@Instagram"
          >
          <input
            id="tiktokInput"
            ref="tiktokInputElement"
            v-model="tiktokInput"
            type="text"
            class="
                social
                social-tiktok
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                bg-transparent
                bg-clip-padding
                border-0
                border-b border-solid border-gray-300
                transition
                ease-in-out
                focus:ring-0
                m-0
                focus:border-space-orange focus:outline-none
              "
            placeholder="@TikTok"
          >
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="moon-orange-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">
const { twitterInput, instagramInput, tiktokInput } = useSocialSlide()

watch(twitterInput, (value) => {
  twitterInput.value = validateHandle(value, 'twitter')
})

watch(instagramInput, (value) => {
  instagramInput.value = validateHandle(value, 'instagram')
})

watch(tiktokInput, (value) => {
  tiktokInput.value = validateHandle(value, 'tiktok')
})

</script>
<style>

.moon-orange-bg {
  background-image: url('/img/moon-orange.webp');
  background-size: cover;
  background-position: right;
}

.social {
  background-repeat: no-repeat;
  background-position: left 45%;
  background-size: 15px 15px;
  padding-left: 30px;
  font-family: 'Monument';
}

.social-twitter {
  background-image: url('/social/soc-x-white.svg');
}

.social-insta {
  background-image: url('/social/soc-ig-white.svg');
}

.social-tiktok {
  background-image: url('/social/soc-tiktok-white.svg');
}

</style>
