<template>
  <svg
    class="w-12 h-12"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#6D6D6D"/>
    <path d="M15.3125 25.5036V14.5856C15.3125 13.8166 16.1443 13.3354 16.811 13.7187L26.3048 19.1777C26.9735 19.5622 26.9735 20.527 26.3048 20.9115L16.811 26.3705C16.1443 26.7538 15.3125 26.2726 15.3125 25.5036Z" fill="#252525"/>
  </svg>
</template>
