<template>
  <SlideBase :forward="false" :back="false" uploading :single-pane="true">
    <template #left>
      <div class="gap-y-5 lg:px-4 flex-col sm:flex-grow flex justify-center items-center">
        <h1>
          Uploading your sighting...
        </h1>
        <p v-if="files.length > 0" class="text-lg font-monument text-gray-dark">
          Please keep this browser tab open until all of your files have been successfully uploaded.
        </p>
        <div class="flex flex-col gap-y-4 font-monument">
          <UploadFile v-for="uploadable in files" :key="uploadable.id" :uploadable="uploadable" />
          <div>
            <!-- don't show submit success until all files have uploaded -->
            <div
              v-if="!initialLoading && initialCalled && !initialSightingError && initialSightingResult && submitSuccess"
              class="gap-y-2 flex-col gap-x-5 items-center border border-not-so-white border-opacity-20 rounded-lg py-4 px-5"
            >
              <div class="flex gap-x-2">
                Submitted sighting details
                <IconCheck class="h-5" />
              </div>
              <div class="text-sm font-bold text-gray-medium">
                Your case number is #{{ initialSightingResult?.submit_sighting?.incident_survey?.nid }}
              </div>
            </div>
            <div
              v-else-if="initialSightingError"
              class="font-monument font-light flex flex-col gap-y-2 items-center border border-rose-600 rounded-lg py-4 px-5"
            >
              <div>
                Error submitting sighting details
              </div>
              <span class="text-xs">
                {{ initialSightingError }}
              </span>
            </div>
            <div
              v-else
              class="font-monument font-light flex gap-x-5 items-center border border-opacity-20 border-not-so-white rounded-lg py-4 px-5 opacity-30"
            >
              <div>
                Submitting sighting details
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-2/3">
        <div class="peninsula-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script lang="ts" setup>
import log from 'consola'
import { event } from 'vue-gtag'
import SubmitSighting from '~/gql/SubmitSighting.graphql'
import {
  SubmitSightingMutation,
  SubmitSightingMutationVariables
} from '~/utils/generated/graphql'

const segment = useSegment()
const { phoneNumberFull, emailInput, emailOptIn, smsOptIn, submitAnonymously } = useNameSlide()
const { fingerprint } = useFingerprint()
const { submitSightingInput, initialSightingResult } = useSubmission()
const { files } = useFileManager()
const { nid: successNid, webSubmitterId } = useSuccess()

const { mutate: initialSightingMutation, error: initialSightingError, loading: initialLoading, called: initialCalled } =
  useMutation<SubmitSightingMutation, SubmitSightingMutationVariables>(SubmitSighting)

const isSubmitting = useState('isSubmitting', () => false)
const submitSuccess = useState<boolean | null>('submitSuccess', () => null)
const env = useRuntimeConfig().public.environment

onMounted(() => {
  log.info('onMounted')
  if (successNid.value === null) {
    trackSubmissionStarted()
    submitSurvey()
  }
})

watch(webSubmitterId, async (uid) => {
  log.info('watch webSubmitterId', uid)
  if (uid) {
    await navigateTo('/confirmation/' + uid)
  }
})

function trackSubmissionStarted() {
  segment.track('submission_report_upload_started', {}, { traits: { visitorId: fingerprint.value }})
}

async function submitSurvey() {
  isSubmitting.value = true
  const sightingVariables: SubmitSightingMutationVariables = { sighting: submitSightingInput.value! }

  const sightingResult = await initialSightingMutation(sightingVariables)

  if (initialSightingError.value) {
    segment.track('submission_report_upload_failed', {}, { visitorId: fingerprint.value })
    log.error(initialSightingError.value)
    submitSuccess.value = false
    isSubmitting.value = false
    return
  }

  initialSightingResult.value = sightingResult?.data ?? null
  const mediaTokens = initialSightingResult.value?.submit_sighting?.media_tokens || []

  for (const [i, uploadable] of files.value.entries()) {
    const token = mediaTokens[i]
    segment.track('submission_media_upload_started', {
      incidentSurveyId: initialSightingResult.value?.submit_sighting?.id,
      mimetype: uploadable.file.type,
      sizeInBytes: uploadable.file.size
    }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
    await uploadable.uploadWithToken(token).then(() => {
        segment.track('submission_media_uploaded', {
          incidentSurveyId: initialSightingResult.value?.submit_sighting?.id,
          mimetype: uploadable.file.type,
          sizeInBytes: uploadable.file.size
        }, {
          traits: {
            visitorId: fingerprint.value
          }
        })
      }).catch((error) => {
        segment.track('submission_media_upload_failed', {
          incidentSurveyId: initialSightingResult.value?.submit_sighting?.id,
          mimetype: uploadable.file.type,
          sizeInBytes: uploadable.file.size
        }, {
          traits: {
            visitorId: fingerprint.value
          }
        })
        log.error(error)
        submitSuccess.value = false
      })
  }

  if (!submitAnonymously.value && (env === 'production' || env === 'staging')) {
    // phone or email
    if (phoneNumberFull.value) {
      segment.identify({
        phone: phoneNumberFull.value,
        subscription_groups: [
          {
            subscription_group_id: '939d2fc9-73be-4d1b-a1aa-81c485d774f2',
            subscription_state: smsOptIn.value ? 'subscribed' : 'unsubscribed'
          },
          {
            subscription_group_id: '8a282854-0fbc-42c2-ad94-3b69b4471201',
            subscription_state: smsOptIn.value ? 'subscribed' : 'unsubscribed'
          }
        ],
        visitorId: fingerprint.value 
      })
    } else if (emailInput.value) {
      segment.identify({
        email: emailInput.value,
        subscription_groups: [
          {
            subscription_group_id: 'f439e438-9e6b-431e-b697-4eea349c9f64',
            subscription_state: emailOptIn.value ? 'subscribed' : 'unsubscribed'
          },
          {
            subscription_group_id: '4da1a243-a8e3-47cc-bd86-75a924fee9d0',
            subscription_state: emailOptIn.value ? 'subscribed' : 'unsubscribed'
          },
        ],
        visitorId: fingerprint.value
      })
    }
  }

  event('submit_sighting', {
    event_category: 'sighting',
    event_label: 'submit_sighting',
    value: 1
  })

  submitSuccess.value = true
  successNid.value = initialSightingResult.value?.submit_sighting?.incident_survey?.nid ?? null
  webSubmitterId.value = initialSightingResult.value?.submit_sighting?.id ?? null
}

</script>

<style>

.peninsula-bg {
  background-image: url('/img/peninsula.jpeg');
  background-size: cover;
  background-position: center;
}

</style>
