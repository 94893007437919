export function calculateLineHeadPosition (e: MouseEvent, element: HTMLElement) {
  let left = 0
  let clientX = 0

  const {
    width: progressWidth,
    left: progressLeft,
    right: progressRight
  } = element.getBoundingClientRect()

  if (e.target instanceof HTMLElement) {
    left = e.target.getBoundingClientRect().left
    clientX = e.clientX - left // x position within the element.
  }
  let pos = clientX / progressWidth

  if (e.clientX < progressLeft) { pos = 0 }
  if (e.clientX > progressRight) { pos = 1 }

  pos = pos < 0 ? 0 : pos
  pos = pos > 1 ? 1 : pos

  return pos
}

export function convertTimeMMSS (seconds: number) {
  return new Date(seconds * 1000).toISOString().substr(14, 5)
}
