<template>
  <div>
    <div
      class="border cursor-pointer border-not-so-white border-opacity-50 group w-[150px] h-[150px] sm:w-auto sm:aspect-w-10 sm:h-auto sm:aspect-h-10 overflow-hidden rounded-lg"
    >
      <img
        v-if="file.type === 'image'"
        :src="file.url"
        :alt="file.file.name"
        class="pointer-events-none object-cover group-hover:opacity-75 z-10"
        onerror="this.style.display='none'"
      >
      <video
        v-else-if="file.type === 'video'"
        :src="file.url"
        :alt="file.file.name"
        class="object-cover group-hover:opacity-75"
        :controls="props.active"
      />
      <div v-else-if="file.type === 'audio'" class="w-full h-full flex justify-center items-center">
        <audio-player
          small
          :src="file.url"
          :alt="file.file.name"
        />
      </div>
      <!-- <IconCamera v-if="file.type === 'image'" class="z-0 text-neutral-300 p-5" />
      <IconVideoCamera v-else-if="file.type === 'video'" class="z-0 text-neutral-300 p-5" />
      <IconDocument v-else class="z-0 text-neutral-300 p-5" /> -->
      <!-- <button type="button" class="absolute inset-0 focus:outline-none"> -->
      <!-- <span class="sr-only">View details for {{ file.file.name }}</span> -->
      <!-- </button> -->
    </div>
    <div class="sm:flex mt-2 items-center justify-between">
      <div class="pointer-events-none truncate text-sm pr-2 font-medium text-gray-dark">
        {{ file.file.name }}
      </div>
      <div class=" font-light text-space-orange">
        <button class="text-xs whitespace-nowrap" @click="removeClicked">
          Remove X
        </button>
      </div>
    </div>
    <p class="pointer-events-none block text-xs font-medium text-gray-black">
      {{ humanFileSize( file.file.size, true) }}<br>
      <!-- {{ (files[idx].tags as any)?.model }}<br> -->
      <!-- {{ (files[idx].tags as any)?.date }} -->
      <!-- {{ (file.tags as any)?.date }} -->
      <span v-for="e in exifLabels" :key="e">{{ e }}<br></span>
    </p>
  </div>
</template>
<script setup lang="ts">
import * as ExifReader from 'exifreader'
import AudioPlayer from '@/components/input/AudioPlayer.vue'
import { IUploadableFile } from '~/composables/use-file-manager'
const { removeFile } = useFileManager()
const props = defineProps<{ file: IUploadableFile, active: boolean }>()

const file = computed(() => props.file)
const exif = ref<ExifReader.ExpandedTags | null>(null)

onMounted(() => {
  const f = props.file
  if (f.type === 'image') {
    ExifReader.load(f.file, { expanded: true })
      .then((t) => {
        log.debug(`Exif tags for ${f.file.name} `, t)
        exif.value = t

        return t
      })
      .catch((e) => {
        log.error(e)
        return null
      })
  }
})

function removeClicked() {
  // removeFile(file.value)
  removeFile(file.value)
}

const exifLabels = computed(() => {
  if (exif.value === null) { return [new Date(file.value.file.lastModified).toISOString()] }
  const t = exif.value
  // const location = t.gps !== undefined ? { lat: t.gps.Latitude, lon: t.gps.Longitude, alt: t.gps.Altitude } : undefined
  const model = t.exif?.['Model Version']?.description ?? t.exif?.Make?.description ?? undefined
  const date = t.exif?.DateTimeOriginal?.description ?? t.exif?.DateTimeDigitized?.description ?? t.exif?.DateTime?.description ?? file.value.file.lastModified

  return [model, date].filter(v => v !== undefined) as string[]
})

</script>
