<template>
  <SlideBase submit :forward="false" back :single-pane="true">
    <template #left>
      <div class="gap-y-5 flex-col sm:flex-grow flex justify-around">
        <div class="flex flex-col gap-y-5 flex-grow w-full justify-center mx-auto">
          <h1>
            Receive updates on your case
          </h1>
          <p class="font-monument">These contact details are simply for us to reach you about your report. We don’t send many updates and we never sell user data. These details are not published in your public report, where we default to keeping you anonymous.</p>
          <AccordionContainer>
            <AccordionPanel title="Use Email" id="email">
              <input
                id="emailInput"
                ref="emailInputElement"
                v-model="emailInput"
                :disabled="!!submitAnonymously"
                type="email"
                class="
                      form-control
                      block
                      uppercase
                      w-full
                      px-3
                      pl-0
                      py-1.5
                      text-base
                      font-normal
                      bg-transparent
                      bg-clip-padding
                      border-0
                      border-b border-solid border-gray-300
                      transition
                      ease-in-out
                      focus:ring-0
                      m-0
                      focus:border-space-orange focus:outline-none
                    "
                placeholder="Email"
              >
              <div>
                <InputCheckbox
                  v-model="emailOptIn"
                  :disabled="!!submitAnonymously"
                  class="text-sm inline-flex"
                  :label="`I agree to be contacted via email from Enigma`"
                />
                <p class="font-monument text-gray-dark text-xs text-left">
                  Enigma encourages email opt-in to provide submission status updates and promotional messages at the email provided.
                </p>
              </div>
            </AccordionPanel>
            <AccordionPanel title="Use Phone Number" id="phone">
              <input
                id="phoneInput"
                ref="phoneInputElement"
                v-model="phoneNumberInput"
                :disabled="!!submitAnonymously"
                type="tel"
                class="
                      form-control
                      block
                      uppercase
                      w-full
                      px-3
                      pl-0
                      py-1.5
                      text-base
                      font-normal
                      bg-transparent
                      bg-clip-padding
                      border-0
                      border-b border-solid border-gray-300
                      transition
                      ease-in-out
                      focus:ring-0
                      m-0
                      focus:border-space-orange focus:outline-none
                    "
                placeholder="Phone number"
              >
              <div>
                <InputCheckbox
                  v-model="smsOptIn"
                  :disabled="!!submitAnonymously"
                  class="text-sm inline-flex"
                  :label="`I agree to be contacted via SMS from Enigma`"
                />
                <p class="font-monument text-gray-dark text-xs text-left">
                  Enigma encourages text opt-in to submission status updates and promotional messages at the cell number provided.
                </p>
              </div>
            </AccordionPanel>
            <AccordionPanel title="Opt out of updates" id="optout">
              <InputCheckbox
                v-model="submitAnonymously"
                :disabled="!!emailInput || !!phoneNumberInput"
                label="I don’t want to provide my contact information and am opting out of any messaging that includes updates to my submission."
                class="inline-flex items-center text-sm"
              />
            </AccordionPanel>
          </AccordionContainer>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="earth-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">
import 'intl-tel-input/build/css/intlTelInput.css'

const { emailInput, emailOptIn, phoneNumberInput, phoneNumberFull, phoneNumberValid, smsOptIn, submitAnonymously } = useNameSlide()

const phoneInputElement = ref(null)

onMounted(async () => {
  const intlTelInput = await import('intl-tel-input').then(m => m.default)
  const itl = intlTelInput(phoneInputElement.value!, {
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js'
  })

  watch(phoneNumberInput, (value) => {
    phoneNumberValid.value = itl.isValidNumber()
    phoneNumberFull.value = itl.getNumber()
  })
})

</script>
<style>
.iti__country-list {
  background-color: rgb(37 37 37);
  border: 1px solid rgb(94, 94, 94);
}
</style>
