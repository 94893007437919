<template>
  <div class="flex flex-col flex-grow gap-y-4 overflow-y-auto p-4 pt-6 md:max-h-slide">
    <div class="mx-auto text-center lg:p-4">
      <p class="font-light text-sm">
        Before submitting, consider whether what you saw behaved like the phenomena below. These known
        phenomena are often confused with UAP.
      </p>
      <p class=" text-sm pt-2">
        <a
          href="//enigmalabs.io/blog/10-objects-commonly-mistaken-for-uap?ref=submit-sighting"
          target="_blank"
          class="text-space-orange"
        >Read
          more</a> on our blog.
      </p>
    </div>
    <div class="flex flex-wrap gap-y-4 flex-grow items-center">
      <div
        v-for="item in mistakenShapes"
        :key="item.name"
        class="w-1/2 md:w-1/2 px-4 lg:px-6 text-center flex flex-col gap-y-2"
      >
        <video
          :alt="'A video of ' + item.name.toLowerCase()"
          muted="true"
          autoplay
          loop
          playsinline
          class="rounded md:rounded-2xl shadow-lg"
          :poster="item.image + '.png'"
        >
          <source :src="item.image + '.webm'" type="video/webm">
          <source :src="item.image + '.mp4'" type="video/mp4">
        </video>
        <div class="tracking-wide text-xs md:text-base lg:text-base pt-2 uppercase">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

const mistakenShapes = [
  {
    name: 'Satellites',
    image: '/mistakenitems/Starlink'
  },
  {
    name: 'Drones',
    image: '/mistakenitems/Drones'
  },
  {
    name: 'Lanterns',
    image: '/mistakenitems/Lanterns'
  },
  {
    name: 'Flares',
    image: '/mistakenitems/MilitaryFlare'
  },
  {
    name: 'Rockets',
    image: '/mistakenitems/Rocket'
  },
  {
    name: 'Search Lights',
    image: '/mistakenitems/Search'
  },
  {
    name: 'Weather',
    image: '/mistakenitems/Weather'
  },
  {
    name: 'Aircraft',
    image: '/mistakenitems/Aircraft'
  }
]
</script>
