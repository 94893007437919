<template>
  <SlideBase forward start>
    <template #left>
      <div class="text-white md:text-not-so-black pb-6 md:pb-0 gap-y-3 flex-col flex-grow flex justify-center items-start text-left md:w-3/4 xl:mx-auto">
        <h1 class="onboarding-header text-white md:text-not-so-black">Share your sighting</h1>
        <p class="text-lg leading-6 font-monument">
          Welcome to Enigma, the largest global UAP/UFO sighting alert network and database. We're grateful you're here.
        </p>
        <p class="text-lg leading-6 font-monument">
          By reporting your sighting, you're helping thousands of scientists, researchers, and witnesses like you crowdsolve this age-old mystery.
        </p>
        <p class="text-lg leading-6 font-monument">
          The report will take 2-4 minutes. We will then review it and notify you whether the sighting has been accepted, usually within a few hours.
        </p>
        <p class="text-lg leading-6 font-monument">
          If you have questions or feedback on the submission experience, email us at <a href="mailto:support@enigmalabs.io" class="underline md:text-space-orange">support@enigmalabs.io</a>.
        </p>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="clouds-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>

<script setup lang="ts">
</script>
<style>
.clouds-bg {
  background-image: url('/img/clouds.png');
  background-size: cover;
  background-position: right;
}
@media (max-width: 768px) {
  .onboarding-header {
    font-size: 2.25rem;
    line-height: 3rem;
  }
}
</style>
