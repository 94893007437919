<template>
  <div class="p-4 h-full">
    <div class="flex md:flex-col flex-grow h-full gap-y-4 overflow-y-auto items-center justify-around md:justify-center">
      <div v-for="item in features" :key="item.name">
        <div  
          :class="[`
            bg-feature
            bg-${item.slug}
            bg-cover
            bg-center
            rounded-xl
            overflow-hidden
            border
            border-gray-black`
          ]"
        >
        </div>
        <div class="text-sm text-center uppercase text-gray-black tracking-wide pt-4">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const features = [
    {
      name: 'Wings',
      slug: 'wings'
    },
    {
      name: 'Rotors',
      slug: 'rotors'
    },
    {
      name: 'Plume',
      slug: 'plume'
    },
  ]
</script>
<style>
.bg-feature {
  width: 86px;
  height: 86px;
}
@media (min-width: 768px) {
  .bg-feature {
    width: min(50vw, 270px);
    height: min(33vw, 180px);
  }
}
.bg-wings {
  background-image: url('/img/wings.jpg');
}
.bg-rotors {
  background-image: url('/img/rotors.jpg');
}
.bg-plume {
  background-image: url('/img/plume.jpg');
}
</style>
