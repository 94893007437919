<template>
  <div class="w-full bg-gray-dark rounded-full h-1 my-1">
    <div class="bg-space-orange h-full rounded-full w-0" :style="[progressWidth]" />
  </div>
</template>

<script setup>
const props = defineProps({
  progress: {
    type: Number,
    default: 0
  }
})

const progressWidth = computed(() => {
  return `width: ${props.progress}%`
})
</script>
