<template>
  <SlideBase forward :forward-disabled="!inputValid" back :single-pane="true">
    <template #left>
      <div class="gap-y-4 md:gap-y-8 lg:px-4 flex-col md:flex-grow flex justify-center">
        <h1>Share your sighting story</h1>
        <div class="font-monument text-base text-gray-dark text-left leading-6">
          <p class="md:mb-6">
            Helpful information includes:
          </p>
          <ul class="list-disc list-inside indent-2">
            <li>Set the scene. Where were you and what were doing?</li>
            <li>How did you feel during and after the sighting?</li>
            <li>What were the weather and visibility conditions?</li>
            <li>Did other witnesses see it? What were their reactions?</li>
            <li>What did the object look like?</li>
            <li>How did the object move?</li>
            <li>What drew your attention or made you think it was unusual?</li>
          </ul>
        </div>

        <div v-if="!showText" class="w-full">
          <InputAudioRecorder />
        </div>

        <div v-if="showText" class="w-full">
          <label class="block">
            <textarea
              v-model="storyInput"
              placeholder="Tell us more about your sighting."
              class="
              block w-full
              bg-not-so-white
              border-gray-dark
              font-monument
              rounded-2xl
              focus:border-space-orange focus:ring focus:ring-space-orange focus:ring-opacity-50
                "
              rows="6"
            />
          </label>
          <div class="flex w-full justify-between font-monument text-xs text-gray-dark mt-2">
            <span>Min 150 characters</span>
            <span>{{ storyInput.length }}</span>
          </div>
        </div>

        <button v-if="!inputValidWithTextMinimum && !isAudioRecording" class="font-monument text-center text-sm text-space-orange" @click="showText = !showText">
          I prefer to {{ showText ? 'record audio' : 'write my story' }}
        </button>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="mars-water-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup>
const { storyInput, inputValid, isAudioRecording } = useStorySlide()
const { audioFiles } = useFileManager()
const showText = ref(false)

const inputValidWithTextMinimum = computed(() => audioFiles.value.length > 0 || storyInput.value.trim().length > 0)
</script>
<style>

.mars-water-bg {
  background-image: url('/img/mars-water.jpg');
  background-size: cover;
  background-position: right;
}

</style>
