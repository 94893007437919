<template>
  <div
    :data-active="active"
    @drop.prevent="onDrop"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
  >
    <slot :drop-zone-active="active" />
  </div>
</template>

<script setup lang="ts">

const emit = defineEmits(['files-dropped'])

// Create `active` state and manage it with functions
const active = ref(false)
let inActiveTimeout: any | null = null // add a variable to hold the timeout key

function setActive () {
  active.value = true
  clearTimeout(inActiveTimeout) // clear the timeout
}
function setInactive () {
  // wrap it in a `setTimeout`
  inActiveTimeout = setTimeout(() => {
    active.value = false
  }, 50)
}

function onDrop (e: any) {
  setInactive()
  emit('files-dropped', [...e.dataTransfer.files])
}

function preventDefaults (e: Event) {
  e.preventDefault()
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults)
  })
})

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults)
  })
})
</script>
