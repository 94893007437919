const invalidCharacters = (social: string) => {
  switch (social) {
    case ('twitter'): 
      return /[^a-zA-Z0-9_]/g
    case ('instagram'):
      return /[^a-zA-Z0-9._]/g
    case ('tiktok'):
      return /[^a-zA-Z0-9._]/g
    default:
      return /[^a-zA-Z0-9_]/g
  }
}

const characterCount = (social: string) => {
  switch (social) {
    case ('twitter'): 
      return 15
    case ('instagram'):
      return 30
    case ('tiktok'):
      return 24
  }
}

export const validateHandle = (handle: string, social: string) => {
  if (handle.length === 0 || !handle) {
    return ''
  }

  let output = handle.replaceAll(' ', '_')
  output = output.replaceAll(invalidCharacters(social), '')
  output = output.substring(0, characterCount(social))

  if (!output.startsWith('@')) {
    output = '@' + output
  }

  return output
}

export const userProfileURL = (handle: string, social: string) => {
  let strippedHandle = handle.replace('@', '')
  switch (social) {
    case ('twitter'): 
      return `https://www.x.com/${strippedHandle}`
    case ('instagram'):
      return `https://www.instagram.com/${strippedHandle}`
    case ('tiktok'):
      return `https://www.tiktok.com/@${strippedHandle}`
    default:
      return `https://www.x.com/${strippedHandle}`
  }
}
