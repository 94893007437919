<template>
  <div class="flex flex-col gap-y-3">
    <div class="font-monument text-lg text-not-so-black leading-6 flex flex-wrap gap-x-3 gap-y-1 items-center align-middle">
      <div>{{ prefix }}</div>
      <div>
        <button
          :class="[
            isActive && selectedIndicies == null ? 'border-space-orange' : 'border-gray-dark',
            selectedIndicies !== null || usingCustomInput ? 'bg-not-so-white border-space-orange text-not-so-black' : '',
            'bg-not-so-white inline-flex gap-x-1 items-center font-monument hover:text-grey-medium border py-1 px-3 rounded text-sm'
          ]"
          @click="toggleActive()"
        >
          {{ label }}
          <IconChevronDown v-if="isActive && selectedIndicies === null" class="h-4 text-not-so-black" />
          <IconChevronRight v-else-if="!isActive && selectedIndicies === null" class="h-4 text-not-so-black" />
        </button>
      </div>
      <div>{{ suffix }}</div>
    </div>
    <div v-show="isActive" class="flex gap-x-2 flex-wrap gap-y-3">
      <button
        v-for="(option, idx) in modelValue.options"
        :key="idx"
        :disabled="modelValue.allowOther && modelValue.otherInput!.length > 0"
        :class="[
          selectedIndicies?.includes(idx) ?
            'bg-not-so-white border-space-orange' :
            'bg-not-so-white text-not-so-black border border-gray-dark hover:bg-space-orange hover:bg-opacity-20 cursor-pointer',
          'font-monument border py-1 px-3 rounded text-sm hover:border-space-orange',
          'disabled:opacity-50'
        ]"
        @click="clickedOption(idx)"
      >
        {{ option.label }}
      </button>
      <div v-if="modelValue.allowOther">
        <input
          v-model.trim="modelValue.otherInput"
          type="text"
          :class="[
            'mt-0',
            'px-2',
            'py-0.5',
            'border ',
            'rounded',
            'focus:ring-0 focus:border-space-orange focus:border-opacity-60',
            'focus:border-0 focus:border-b focus:text-left',
            'text-center',
            'font-monument',
            'bg-not-so-white',
            usingCustomInput ? 'border-space-orange' : 'border-gray-dark'
          ]"
          placeholder="something else"
          autocomplete="false"
          @change="otherInputChanged"
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { MadLibOption } from '~/composables/slides/use-behavior'
const { activeMadLibIdx, singular } = useBehavior()

const props = defineProps<{ modelValue: MadLibOption, idx: number }>()
const emiits = defineEmits(['next'])

const selectedIndicies = computed(() => {
  return props.modelValue.selectedIdx
})

const isActive = computed(() => {
  return activeMadLibIdx.value === props.idx
})

const usingCustomInput = computed(() => {
  return props.modelValue.allowOther && props.modelValue.otherInput!.length > 0
})

const label = computed(() => {
  if (usingCustomInput.value) {
    return props.modelValue.otherInput
  }

  if (selectedIndicies.value === null) {
    return props.modelValue.placeholder
  }

  return selectedIndicies.value.map(val => props.modelValue.options[val].label)
    .map((val) => {
      if (val.includes('(')) {
        return val.replace(/.*\((.*).*\)/g, '$1')
      }
      return val
    })
    .join(', ')
    .replace(/\(.*\)/g, '')
})

const suffix = computed(() => {
  if (props.modelValue.multiple) {
    return props.modelValue.suffix
  }

  if (selectedIndicies.value === null) {
    return props.modelValue.suffix
  }

  return props.modelValue.options[selectedIndicies.value[0]].suffix || props.modelValue.suffix
})

const prefix = computed(() => {
  if ((singular.value === true || singular.value === undefined) && props.modelValue.singularPrefix) {
    return props.modelValue.singularPrefix
  }

  if (props.modelValue.multiple || selectedIndicies.value === null) {
    return props.modelValue.prefix
  }

  return props.modelValue.options[selectedIndicies.value[0]].prefix || props.modelValue.prefix
})

function otherInputChanged() {
  props.modelValue.selectedIdx = null
}

function toggleActive() {
  if (isActive.value) {
    emiits('next')
    return
  }

  activeMadLibIdx.value = props.idx
}

function clickedOption(idx: number) {
  if (selectedIndicies.value === null) {
    props.modelValue.selectedIdx = [idx]
    if (!props.modelValue.multiple) {
      emiits('next')
    }
    return
  }

  if (!props.modelValue.multiple) {
    if (selectedIndicies.value.includes(idx)) {
      props.modelValue.selectedIdx = null
      return
    }

    props.modelValue.selectedIdx = [idx]
    emiits('next')
    return
  }

  if (selectedIndicies.value.includes(idx)) {
    const deselected = selectedIndicies.value.filter(i => i !== idx)

    if (deselected.length == 0) {
      props.modelValue.selectedIdx = null
      return
    }
    props.modelValue.selectedIdx = deselected
    return
  }

  props.modelValue.selectedIdx = selectedIndicies.value.concat(idx)
}

</script>
