<template>
  <div class="">
    <div v-if="small">
      <div class="w-20 h-20 sm:w-32 sm:h-32 flex justify-center items-center relative">
        <div v-if="isPlaying" class="bg-space-orange rounded-full absolute w-20 h-20 sm:w-32 sm:h-32 inset-0 z-10 animate-ping blur-lg" />
        <pause-icon v-if="isPlaying" class="cursor-pointer z-20" @click="playback" />
        <play-icon v-else class="cursor-pointer z-20" @click="playback" />
      </div>
    </div>

    <div v-else class="flex justify-between items-center gap-4">
      <pause-icon v-if="isPlaying" class="cursor-pointer" @click="playback" />
      <play-icon v-else class="cursor-pointer" @click="playback" />
      <line-control
        ref-id="progress"
        :percentage="progress"
        @change-linehead="_onUpdateProgress"
      />
      <div class="text-gray-medium font-monument tracking-wide select-none w-16 text-right">
        {{ playedTime }}
      </div>
    </div>

    <audio
      id="playerId"
      ref="player"
      :src="audioSource"
    />
  </div>
</template>

<script>
import LineControl from './Player/LineControl.vue'
import PlayIcon from './Player/PlayIcon.vue'
import PauseIcon from './Player/PauseIcon.vue'
import { convertTimeMMSS } from './lib/utils'

export default {
  components: {
    LineControl,
    PlayIcon,
    PauseIcon
  },
  props: {
    src: { type: String, default: '' },
    record: { type: Object, default: () => {} },
    filename: { type: String, default: '' },
    small: { type: Boolean, default: false }
  },
  data () {
    return {
      player: undefined,
      isPlaying: false,
      duration: convertTimeMMSS(0),
      playedTime: convertTimeMMSS(0),
      progress: 0
    }
  },
  computed: {
    audioSource () {
      const url = this.src || this.record?.url
      if (url) {
        return url
      } else {
        this._resetProgress()
        return false
      }
    },
    playBtnIcon () {
      return this.isPlaying ? 'pause' : 'play'
    },
    playerUniqId () {
      return 'audio-player'
    }
  },
  mounted: function () {
    this.player = document.getElementById('playerId')

    this.player.addEventListener('ended', () => {
      this.isPlaying = false
    })

    this.player.addEventListener('loadeddata', () => {
      this._resetProgress()
      this.duration = convertTimeMMSS(this.player.duration)
    })

    this.player.addEventListener('timeupdate', this._onTimeUpdate)

    //
    // this.$eventBus.$on('remove-record', () => {
    //   this._resetProgress()
    // })
  },
  methods: {
    playback () {
      if (!this.audioSource) {
        return
      }

      if (this.isPlaying) {
        this.player.pause()
      } else {
        setTimeout(() => { this.player.play() }, 0)
      }

      this.isPlaying = !this.isPlaying
    },
    _resetProgress () {
      if (this.isPlaying) {
        this.player.pause()
      }

      this.duration = convertTimeMMSS(0)
      this.playedTime = convertTimeMMSS(0)
      this.progress = 0
      this.isPlaying = false
    },
    _onTimeUpdate () {
      this.playedTime = convertTimeMMSS(this.player.currentTime)
      this.progress = (this.player.currentTime / this.player.duration) * 100
    },
    _onUpdateProgress (pos) {
      if (pos || pos === 0) {
        this.player.currentTime = pos * this.player.duration
      }
    },
    _onChangeVolume (val) {
      if (val) {
        this.player.volume = val
      }
    }
  }
}
</script>
