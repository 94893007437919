<template>
  <SlideBase forward back :forward-disabled="!inputValid" single-pane>
    <template #left>
      <div class="gap-y-10 flex-col md:flex-grow flex items-start justify-center text-not-so-white">
        <div class="flex flex-col gap-y-6 md:mb-10">
          <h1>
            Did any sensors detect the object?
          </h1>
          <div class="flex flex-wrap items-center leading-4 gap-y-4 text-md gap-x-2 sm:gap-x-0">
            <InputCheckbox
              v-for="option in sensorOptions"
              :key="option.id"
              v-model="option.selected"
              :disabled="noSensorInput"
              class="
              flex-grow
              sm:basis-1/2"
              :label="option.description"
            />
            <InputCheckbox
              v-model="noSensorInput"
              :disabled="sensorOptions.some(o => o.selected) || otherSensorInput.length > 0"
              class="
              flex-grow
              sm:basis-1/2"
              :label="`None`"
            />

            <input
              v-model="otherSensorInput"
              type="text"
              class="
                basis-1/2
                border-0
                border-b-2
                border-gray-300
                font-monument
                focus:ring-0
                focus:border-space-orange
                focus:border-opacity-60
                pl-0
                bg-transparent
              "
              placeholder="Other, specify"
            >
          </div>
        </div>
        <div class="flex flex-col gap-y-6 md:mb-10 text-md">
          <h1>
            Are you any of the following?
          </h1>
          <div class="flex flex-wrap leading-4 gap-y-4 gap-x-2 sm:gap-x-0">
            <InputCheckbox
              v-for="option in expertiseOptions"
              :key="option.id"
              v-model="option.selected"
              :disabled="noProfessionInput"
              class="flex-grow  sm:basis-1/2"
              :label="option.description"
            />
            <InputCheckbox
              v-model="noProfessionInput"
              :disabled="expertiseOptions.some(o => o.selected)"
              class="
              flex-grow
              sm:basis-1/2"
              :label="`None`"
            />
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="diamond-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">

const { expertiseOptions, sensorOptions, otherSensorInput, noProfessionInput, noSensorInput, inputValid } = useSensorSlide()
const segment = useSegment()
const { fingerprint } = useFingerprint()

watch(inputValid, () => {
  const sensorInput = otherSensorInput.value.length > 0 ? otherSensorInput.value : noSensorInput.value ? 'None' : sensorOptions.value.filter(s => s.selected).map(s => s.description)
  const professionInput = noProfessionInput.value ? 'None' : expertiseOptions.value.filter(s => s.selected).map(s => s.description)
  
  segment.track('submission_sensors_updated', {
    sensors: sensorInput
  }, {
    traits: {
      visitorId: fingerprint.value
    }
  })

  segment.track('submission_reporter_professions_updated', {
    professions: professionInput
  }, {
    traits: {
      visitorId: fingerprint.value
    }
  })
})
</script>
<style>

.diamond-bg {
  background-image: url('/img/diamond-mine.jpeg');
  background-size: cover;
  background-position: right;
}

</style>
