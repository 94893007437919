<template>
  <SlideBase :forward-disabled="!inputValid" back :single-pane="true">
    <template #left>
      <div class="gap-y-5 flex-col md:flex-grow flex justify-center">
        <h1>How many witnesses (including yourself)?</h1>
        <div>
          <div class="relative text-md mx-auto w-[75%] md:w-full">
            <input
              v-model="witnessCountInput"
              type="number"
              placeholder="Enter number"
              min="1"
              class="witness-input font-monument block w-full pl-0 pr-16 focus:border-space-orange bg-transparent border-0 border-b border-gray-300 focus:ring-0"
            >
            <div class="absolute w-0 inset-y-0 right-0 flex items-center pr-16 text-sm">
              <span>{{ witnessInputLabel }}</span>
            </div>
          </div>
          <div v-if="witnessCountInput === 1" class="mt-2">
            <span class="text-xs text-space-orange">
              (just me)
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="liquid-red-bg w-full h-full flex flex-col justify-center items-center">
          <div class="flex flex-col w-full px-8 py-20 sm:p-0 sm:w-2/3 sm:p-0 gap-y-6 text-center uppercase text-not-so-white">
            <p>
              26% of sightings have more than 1 witness.<br>
            </p><p>
              More eyewitnesses means more corroboration of the sighting.
            </p>
          </div>
        </div>
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">
const { witnessCountInput, inputValid } = useWitnessCountSlide()
const { fingerprint } = useFingerprint()
const segment = useSegment()

const witnessInputLabel = computed(() => {
  if (witnessCountInput.value === 1 || witnessCountInput.value === null) {
    return 'person'
  } else {
    return 'people'
  }
})

watch(witnessCountInput, () => {
  segment.track('submission_witness_count_updated', {
    witnessCount: witnessCountInput.value
  }, {
    traits: {
      visitorId: fingerprint.value
    }
  })
})
</script>
<style>
.witness-input::placeholder {
  color: #FF5935 !important;
}

.witness-input::-webkit-outer-spin-button, 
.witness-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.witness-input {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>