<template>
  <SlideBase forward back :forward-disabled="!inputValid || !dateTimeInputValid">
    <template #left>
      <div class="text-white flex flex-col md:flex-grow justify-evenly">
        <div class="mb-6 flex flex-col gap-y-4">
          <h1>When did the sighting happen?</h1>
          <p class="font-monument text-lg leading-6 text-gray-dark">If you don't remember the exact details, that's okay. Just put your best guess.</p>
          <div>
            <VueDatePicker
              ref="dateTimeInputElement"
              v-model="dateTimeInput"
              uid="datetime"
              :disabled="tzResponse === null"
              :max-date="DateTime.now().setZone(tzResponse?.timeZoneId).toJSDate()"
              :is-24="false"
              :timezone="tzResponse?.timeZoneId"
              :clearable="false"
              time-picker-inline
              minutes-grid-increment="1"
              position="left"
              input-class-name="custom-input"
              calendar-class-name="custom-calendar"
              menu-class-name="custom-menu"
              placeholder="Select Date"
            />
            <div v-if="tzResponse" class="md:mt-2">
              <span class="font-monument text-xs text-gray-dark" data-test="tz-response">
                {{ tzResponse.timeZoneName }}
              </span>
            </div>
            <div v-else class="md:mt-2">
              <span class="font-monument text-xs text-red-500">
                Date will be set to your sighting location's time zone. Please select a location in the previous step.
              </span>
            </div>
          </div>
          <div v-if="futureDateTime" class="mt-2">
            <span class="text-xs text-gray-dark">
              Please choose a valid date and time.
            </span>
          </div>
        </div>
        <div class="flex flex-col justify-start gap-y-4">
          <h1>How long did the sighting last?</h1>

          <div class="relative text-md w-full md:w-3/4">
            <InputDuration v-model="durationInput" />
            <span class="text-xs font-monument text-gray-dark">
              {{ durationReadable }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2 flex-grow md:flex-grow-0 flex" style="min-height: 500px">
        <div id="map-date-time-slide" class="flex-grow" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script lang="ts" setup>
import { tzForLocation } from '~/composables/use-user-inputs'
import VueDatePicker from '@vuepic/vue-datepicker'
import { DateTime } from 'luxon'

const segment = useSegment()
const { fingerprint } = useFingerprint()
const { markerLocation, yesOrNoPII } = useLocationSlide()
const { durationInput, dateTimeInput, timeZone: tzResponse, inputValid, dateTimeInputValid, secondInput, minuteInput, hourInput } = useDateTimeSlide()
const dateTimeInputElement = ref<HTMLInputElement | null>(null)

const defaultCenter: [number, number] = [-99, 30]
const defaultZoom = 0

let map: any

const durationReadable = computed(() => {
  let seconds
  let minutes
  let hours

  if (durationInput.value && durationInput.value > 0) {
    seconds = `${secondInput.value || 0} ${secondInput.value === 1 ? 'second' : 'seconds'}`
    minutes = `${minuteInput.value || 0} ${minuteInput.value === 1 ? 'minute' : 'minutes'}`
    hours = `${hourInput.value || 0} ${hourInput.value === 1 ? 'hour' : 'hours'}`

    return `${hours}, ${minutes}, ${seconds}`
  }

  return null
})

const futureDateTime = computed(() => {
  if (dateTimeInput.value && tzResponse.value) {
    const zoned = zonedDatetime(dateTimeInput.value, tzResponse.value.timeZoneId)
    const now = DateTime.now().setZone(tzResponse.value.timeZoneId)

    return zoned > now
  }

  return false
})

const popupText = computed(() => {
  let html = ''
  if (durationInput.value) {
    html += `
      <div class="text-left">
        <div class="text-xs uppercase">Duration: ${durationInput.value} seconds</div>
      </div>
    `
  } else {
    html += `
      <div class="text-left">
        <div class="text-xs uppercase">Duration: --</div>
      </div>
    `
  }

  if (dateTimeInput.value && tzResponse.value) {
    const zoned = zonedDatetime(dateTimeInput.value, tzResponse.value.timeZoneId)

    html += `
      <div class="text-left">
        <div class="text-xs uppercase">Date: ${zoned.toLocaleString(DateTime.DATE_SHORT)}</div>
        <div class="text-xs uppercase">Time: ${zoned.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}</div>
      </div>
    `
  } else {
    html += `
      <div class="text-left">
        <div class="text-xs uppercase">Date: --</div>
        <div class="text-xs uppercase">Time: --</div>
      </div>
    `
  }

  return html
})

function drawRadius(center: any) {
  map.addSource('radius', {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [center.lng, center.lat]
      },
      properties: {
        title: 'PII Radius'
      }
    }
  })
  map.addLayer({
    id: 'radius',
    type: 'circle',
    source: 'radius', // reference the data source
    layout: {},
    paint: {
      'circle-color': '#FF5935', // space orange fill
      'circle-opacity': 0.5,
      'circle-radius': 50
    }
  })
}

onMounted(async () => {
  const mapboxgl = await import('mapbox-gl')

  const marker = new mapboxgl.Marker()
  const popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false, closeOnMove: false })

  map = new mapboxgl.Map({
    accessToken: 'pk.eyJ1IjoiZW5pZ21hbGFicyIsImEiOiJja2prMzZ6NHA1YnppMnpucHIzdnc3d255In0.jPuo0Fn7BhDGGtlIQ1LotQ',
    container: 'map-date-time-slide',
    style: 'mapbox://styles/enigmalabs/clbgud6ps000o16o8wql85a96',
    center: defaultCenter,
    zoom: defaultZoom,
    interactive: false
  })

  watch(popupText, () => {
    popup.setHTML(popupText.value)
  })

  watch(markerLocation, () => {
    setupMaps()
  })
  setupMaps()

  function setupMaps() {
    fetchTz()
    marker.remove()
    popup.setHTML(popupText.value)
    if (markerLocation.value) {
      marker
        .setLngLat(markerLocation.value)
        .addTo(map)
        .setPopup(popup)
        .togglePopup()
      map.flyTo({ center: markerLocation.value, zoom: 12, duration: 0, essential: false })
      if (yesOrNoPII.value === 'yes') {
        setTimeout(() => {
          drawRadius(markerLocation.value)
        }, 500)
      }
    } else {
      map.flyTo({ center: defaultCenter, zoom: defaultZoom, duration: 0, essential: false })
    }
  }
})

async function fetchTz() {
  if (markerLocation.value) {
    tzResponse.value = await tzForLocation(markerLocation.value)
  }
}

onActivated(() => {
  if (map) {
    map.resize()
    if (yesOrNoPII.value === 'no' && map.getSource('radius')) {
      map.removeLayer('radius')
      map.removeSource('radius')
    } else if (yesOrNoPII.value === 'yes' && !map.getSource('radius')) {
      drawRadius(markerLocation.value)
    }
  }
})

watch(dateTimeInput, () => {
  segment.track('submission_datetime_input_updated', {
    utcDateString: dateTimeInput.value
  }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
})

watch(durationInput, () => {
  segment.track('submission_duration_input_updated', {
    durationInSeconds: durationInput.value
  }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
})

</script>
<style lang="scss">
@import "~/node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "~/node_modules/@vuepic/vue-datepicker/dist/main.css";

.custom-input {
  color: #252525;
  background-color: transparent;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: #252525;
  border-radius: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0 auto;
  font-family: 'Monument';
  font-size: 18px;
  line-height: 24px;
  &:focus {
    border-color: #FF5935;
  }
  @media (min-width: 768px) {
    width: 75%;
  }
}

.custom-input::placeholder {
  color: #FF5935 !important;
}

.custom-input.dp__disabled {
  background-color: rgb(250 250 250 / 0.1);
  opacity: .3;
}

.custom-calendar {
  font-family: 'Monument';
}

.custom-menu {
  width: 350px;
}

.dp__tp_inline_btn_top .dp__tp_inline_btn_bar,
.dp__tp_inline_btn_bottom .dp__tp_inline_btn_bar {
  background-color: #cdcdcd;
}

.dp__pm_am_button,
.dp__overlay_cell_active,
.dp__tp_inline_btn_top:hover .dp__tp_inline_btn_bar,
.dp__tp_inline_btn_bottom:hover .dp__tp_inline_btn_bar,
.dp__active_date {
  background: #FF5935;
}

.dp__action_row {
  padding: 16px 8px 8px;
  .dp__selection_preview {
    max-width: 100% !important;
  }
}

.dp__action_buttons {
  gap: 12px;
  .dp__action_select {
    background: #FF5935;
    &:hover {
      background: #FF5935;
      opacity: .5;
    }
    &:disabled {
      background: #FF5935;
      opacity: .3;
    }
  }
  .dp__action_cancel:hover {
    border-color: #FF5935;
  }
  .dp__action_button {
    font-family: 'Monument';
    border-radius: 1.5rem;
    padding: 12px;
  }
}

.dp__today {
  border-color: #FF5935;
}

.dp__input_icon {
  top: 45% !important;
  padding-left: 0;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-marker {
  background-color: black;
  overflow: hidden;
  fill: black;
  content-visibility: hidden;
  height: 1.4rem;
  width: 1.4rem;
  background-image: url("/UserSingleMarker.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.mapboxgl-popup-content {
  color: black;
  background-color: white;
  border-color: #FF5935;
  border-radius: 1rem;
  border-width: 1.5px;
  font-family: 'OCRF-Regular';
  font-size: 1.2rem;
  padding: 1rem;
}
</style>
