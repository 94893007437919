const _twitterInput = () => useState<string>('twitterInput', () => '')
const _instagramInput = () => useState<string>('instagramInput', () => '')
const _tiktokInput = () => useState<string>('tiktokInput', () => '')

export default function () {
  return {
    twitterInput: _twitterInput(),
    instagramInput: _instagramInput(),
    tiktokInput: _tiktokInput()
  }
}
