<template>
  <SlideBase forward back :forward-disabled="!inputValid">
    <template #left>
      <div class="flex-grow justify-center flex flex-col gap-y-4 max-w-screen">
        <p class="font-monument text-sm">30 more seconds, you're almost there!</p>
        <h1>
          Did you capture any media of the object?
        </h1>

        <div class="w-full ">
          <label class="file">
            <InputDropZone v-slot="{ dropZoneActive }" class="cursor-pointer" @files-dropped="addFiles">
              <!-- <div  border tracking-wide rounded  flex bg-black-70 border-space-orange text-sm justify-center items-center']"> -->
              <div
                :class="[{ 'opacity-40': dropZoneActive },
                         'bg-not-so-white border-gray-black border-dashed border rounded',
                         'text-sm uppercase text-space-orange tracking-wide flex flex-col justify-center items-center',
                         'h-20 sm:h-40'
                ]"
              >
                <span class="text-grey">
                  Upload Video/Image
                </span>
                <span class="hidden md:inline-flex text-xs text-gray-dark font-monument lowercase">&nbsp;or drag and drop</span>
                <input ref="fileInput" type="file" multiple class="opacity-0 w-0 h-0" @change="fileInputChanged">
              </div>

            </InputDropZone>
          </label>
          <button class="font-monument text-left text-sm text-space-orange mt-2 w-full flex gap-1" @click="showMediaHelp = !showMediaHelp">
            <IconHelp class="text-space-orange h-4" /> What makes good media?
          </button>
          <div v-if="showMediaHelp" class="font-monument text-xs text-left leading-6">
            <ol class="list-inside list-decimal indent-2">
              <li>Media is not altered. Raw footage is always preferred.</li>
              <li>Reference points in media. This helps us understand the object's size and movement.</li>
              <li>Still capture and high resolution imagery.</li>
              <li>Multiple angles and videos.</li>
            </ol>
            <p>What is unhelpful?</p>
            <ol class="list-inside list-decimal indent-2">
              <li>Marked up images.</li>
              <li>Watermarks from other media sites.</li>
              <li>Hyper zoomed-in images and videos.</li>
              <li>Screenshots from a different device.</li>
            </ol>
          </div>
        </div>
        <InputCheckbox
          v-if="withoutAudioFiles.length == 0"
          v-model="didntCaptureMediaInput"
          class="text-sm inline-flex text-gray-medium"
          :label="`I didn't capture anything`"
        />
      </div>
      <div class="mx-auto mt-4 sm:mt-0">
        <span v-if="validFiles.length > 0" class="text-sm font-monument">By continuing, you agree to Enigma's
          <a class="text-space-orange underline" href="https://enigmalabs.io/terms" target="_blank">
            media usage terms</a>.
        </span>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="flex-grow md:basis-1/2 flex md:flex-col text-gray-black overflow-y-scroll md:overflow-x-hidden">
        <MistakenItems v-if="!allFiles" />
        <div v-else class="md:flex flex-col flex-grow w-full md:w-auto md:p-4 p-4 lg:p-8 md:gap-y-8 justify-center">
          <div class="justify-center flex">
            <span class="uppercase text-md mx-auto block"><span class="text-space-orange">{{ withoutAudioFiles.length }}</span>
              media files</span>
          </div>
          <div v-if="invalidFiles.length > 0" class="border rounded-2xl p-6 border-not-so-white border-opacity-40">
            <ul>
              <li v-for="file in invalidFiles" :key="'invalid' + file.id" class="text-red-600 break-words text-xs md:text-sm mb-2">
                {{ file.file.name }} is not a supported file type.
              </li>
            </ul>
            <p class="text-xs text-gray-black font-monument">
              Only video, audio, and images are supported through web submission. If you have other files, contact us.
            </p>
            <button class="text-xs text-space-orange whitespace-nowrap" @click="removeClicked">
              Remove X
            </button>
          </div>
          <ImageGrid :files="withoutAudioFiles" />
        </div>
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script lang="ts" setup>
import { event } from 'vue-gtag'
const fileInput = ref<HTMLInputElement | null>(null)
const showMediaHelp = ref(false)
const segment = useSegment()
const { fingerprint } = useFingerprint()
const { addFiles, files, invalidFiles, validFiles, removeFile } = useFileManager()

const { didntCaptureMediaInput, inputValid } = useMediaSlide()

const withoutAudioFiles = computed(() => {
  return validFiles.value.filter(f => f.type !== 'audio')
})

const allFiles = computed(() => {
  return withoutAudioFiles.value?.length > 0 || invalidFiles.value?.length > 0
})

function removeClicked() {
  invalidFiles.value.forEach(file => {
    removeFile(file)
  })
}

function fileInputChanged() {
  const files = fileInput.value?.files
  if (files) {
    addFiles(files)
    event('file_added', {
      event_category: 'submission',
      event_label: 'file added',
      value: files?.length
    })
    for (const file of files) {
      segment.track('submission_set_media', {
        mimetype: file.type,
        sizeInBytes: file.size
      }, {
        traits: {
          visitorId: fingerprint.value
        }
      })
    }
  }
}

</script>
