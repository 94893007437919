<template>
  <SlideBase forward back :forward-disabled="!featureInputValid">
    <template #left>
      <div class="flex flex-col md:pt-2 justify-center items-center gap-y-4 md:gap-y-8 flex-grow w-full">
        <h1 class="self-start">Describe if the {{ uapLabel }}:</h1>
        <MadLibFeatures />
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2" :feature-pane="true">
        <FeatureImages />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">

const { featureInputValid, singular } = useBehavior()

const uapLabel = computed(() => {
  if (singular.value === false) {
    return 'objects'
  } else {
    return 'object'
  }
})

</script>
