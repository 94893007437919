<template>
  <div>
    <form
      class="
        flex
        gap-x-2
        justify-center
        items-center
        w-full
        py-2
        focus-within:border-space-orange
        bg-transparent
        border-0
        border-b
        border-gray-black
        focus-within:ring-0
        mb-2
      "
    >
      <div
        class="
          basis-1/3
          border-[1px]
          border-transparent
          flex
          justify-center
          items-center
          rounded
          py-1
          bg-gray-light
          focus-within:border-space-orange
        "
      >
        <input
          v-model="hourInput"
          type="number"
          step="1"
          min="0"
          max="99"
          placeholder="0"
          aria-label="hour"
          class="
            bg-transparent
            appearance-none
            border-0
            text-center
            text-not-so-black
            p-0
            w-[50px]
            focus:outline-none
            focus:ring-0
          "
          @input="$emit('update:modelValue', calculateInSeconds())"
          @beforeinput="event => validateInput(event)"
        >
        <span class="text-not-so-black">h</span>
      </div>
      <div
        class="
          basis-1/3
          border-[1px]
          border-transparent
          flex
          justify-center
          items-center
          rounded
          py-1
          bg-gray-light
          focus-within:border-space-orange
        "
      >
        <input
          v-model="minuteInput"
          type="number"
          step="1"
          min="0"
          max="60"
          placeholder="0"
          aria-label="minute"
          class="
            bg-transparent
            appearance-none
            border-0
            text-center
            text-not-so-black
            p-0
            w-[50px]
            focus:outline-none
            focus:ring-0
          "
          @input="$emit('update:modelValue', calculateInSeconds())"
          @beforeinput="event => validateInput(event)"
        >
        <span class="text-not-so-black">m</span>
      </div>
      <div
        class="
          basis-1/3
          border-[1px]
          border-transparent
          flex
          justify-center
          items-center
          rounded
          py-1
          bg-gray-light
          focus-within:border-space-orange
        "
      >
        <input
          v-model="secondInput"
          type="number"
          step="1"
          min="0"
          max="60"
          placeholder="0"
          aria-label="second"
          class="
            bg-transparent
            appearance-none
            border-0
            text-center
            text-not-so-black
            p-0
            w-[50px]
            focus:outline-none
            focus:ring-0
          "
          @input="$emit('update:modelValue', calculateInSeconds())"
          @beforeinput="event => validateInput(event)"
        >
        <span class="text-not-so-black">s</span>
      </div>
    </form>
  </div>
</template>

<script setup>
  const { secondInput, minuteInput, hourInput } = useDateTimeSlide()

  defineEmits(['update:modelValue'])

  function calculateInSeconds() {
    const secs = (secondInput.value || 0) + ((minuteInput.value || 0) * 60) + ((hourInput.value || 0) * 60 * 60)
    return secs
  }

  function validateInput(e) {
    const char = e.data
    if (char != null && char !== '' && !/^[0-9]*$/.test(char)) {
      e.preventDefault()
    }
  }

  watch(minuteInput, () => {
    // debounced event to allow for typing
    setTimeout(() => {
      if (minuteInput.value > 60) {
        hourInput.value = hourInput.value + Math.floor(minuteInput.value / 60)
        minuteInput.value = minuteInput.value % 60
      }
    }, 500)
  })

  watch(secondInput, () => {
    // debounced event to allow for typing
    setTimeout(() => {
      if (secondInput.value > 60) {
        minuteInput.value = minuteInput.value + Math.floor(secondInput.value / 60)
        secondInput.value = secondInput.value % 60
      }
    }, 500)
  })
</script>
<style>
input::placeholder {
  color: #9d9d9d !important;
}
</style>