<template>
  <div class="w-full">
    <div :class="['w-full mb-3 border border-gray-dark bg-not-so-white rounded-2xl relative pb-3 pt-6 px-4', {'border-space-orange': isRecording}]">
      <div v-if="selected?.url" class="w-full mb-8">
        <audio-player v-if="selected.url" :record="selected" />
      </div>
      <div v-if="!selected.url" class="font-monument select-none text-right w-full text-gray-dark top-3 absolute right-4">
        Max {{ convertTimeMMSS(time * 60) }}
      </div>

      <div v-if="!selected.url" class="w-full">
        <div class="flex flex-col gap-y-2 justify-center items-center">
          <!-- {{  !!selected?.url }} -->
          <svg
            v-if="!isRecording && !selected.url"
            class="mx-auto cursor-pointer"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="startRecording"
          >
            <circle cx="28" cy="28" r="27" fill="#FAFAFA" stroke="#FF5935" stroke-width="2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0028 15.25C25.7955 15.25 23.9732 17.0799 23.9732 19.3782V27.5833C23.9732 29.8817 25.7955 31.7115 28.0028 31.7115C30.2101 31.7115 32.0324 29.8817 32.0324 27.5833V19.3782C32.0324 17.0799 30.2101 15.25 28.0028 15.25ZM21.9732 19.3782C21.9732 16.0121 24.6545 13.25 28.0028 13.25C31.3511 13.25 34.0324 16.0121 34.0324 19.3782V27.5833C34.0324 30.9495 31.3511 33.7115 28.0028 33.7115C24.6545 33.7115 21.9732 30.9495 21.9732 27.5833V19.3782ZM29.0028 38.7152C31.1099 38.5013 33.1048 37.6151 34.6964 36.1731C36.5374 34.5051 37.7108 32.2099 37.9994 29.7243C38.0631 29.1757 37.67 28.6793 37.1214 28.6156C36.5728 28.5519 36.0764 28.945 36.0127 29.4936C35.7794 31.5034 34.8313 33.3521 33.3536 34.6909C31.8763 36.0293 29.9724 36.7658 28.0031 36.7658C26.0338 36.7658 24.1298 36.0293 22.6526 34.6909C21.1748 33.3521 20.2268 31.5034 19.9934 29.4936C19.9297 28.945 19.4334 28.5519 18.8848 28.6156C18.3362 28.6793 17.9431 29.1757 18.0068 29.7243C18.2954 32.2099 19.4687 34.5051 21.3097 36.1731C22.9012 37.615 24.8959 38.5011 27.0028 38.7152V41.9167C27.0028 42.469 27.4505 42.9167 28.0028 42.9167C28.5551 42.9167 29.0028 42.469 29.0028 41.9167V38.7152Z" fill="#FF5935"/>
          </svg>
          <svg
            v-else-if="isRecording && !selected.url"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="cursor-pointer"
            @click="finishRecording"
          >
            <circle cx="28" cy="28" r="27" fill="#FAFAFA" stroke="#FF5935" stroke-width="2"/>
            <rect x="20" y="20" width="16" height="16" rx="2" fill="#FF5935"/>
          </svg>
          <p v-if="!isRecording && !selected.url" @click="startRecording" class="text-space-orange uppercase cursor-pointer">Record Audio Story</p>
        </div>
      </div>

      <div class="uppercase font-sans absolute bottom-3 right-4 select-none">
        <div v-if="!isRecording && !isPause && selected.url" class="text-gray-black font-monument text-lg">
          {{ selected.duration }}
        </div>
        <div v-else :class="['font-monument text-lg', {'text-space-orange' : isRecording && !isPause, 'text-gray-black': !isRecording && isPause, 'hidden': !isRecording && !isPause }]">
          {{ recordedTime }}
        </div>
      </div>
      <div class="uppercase font-sans absolute bottom-3 left-4">
        <span v-if="isRecording && !isPause" class="text-space-orange cursor-pointer" @click="toggleRecorder">
          Pause
        </span>
        <span v-else-if="isRecording && isPause" class="text-space-orange cursor-pointer" @click="toggleRecorder">
          Resume
        </span>
        <div v-else-if="!isRecording && !isPause && selected.url" class="flex gap-3">
          <span v-show="!deletingAudio" class="text-gray-black cursor-pointer" @click="retry">
            Retry
          </span>
          <span v-show="!canceling" class="text-gray-medium cursor-pointer" @click="deleteAudio">
            Delete
          </span>
        </div>
      </div>
    </div>

    <div v-if="canceling || deletingAudio" class="w-full pt-4 pb-6 px-4">
      <p class="text-gray-black font-monument text-left mb-4">
        <span class="max-w-[35ch] inline-block">
          Do you want to remove the current recording<span v-if="canceling"> and start a new one</span>?
        </span>
      </p>
      <div v-if="canceling" class="flex gap-8">
        <p class="text-gray-black uppercase cursor-pointer" @click="confirm">
          Start new
        </p>
        <p class="text-gray-medium uppercase cursor-pointer" @click="cancel">
          Keep current
        </p>
      </div>
      <div v-if="deletingAudio" class="flex gap-8">
        <p class="text-gray-black uppercase cursor-pointer" @click="confirmDelete">
          Remove recording
        </p>
        <p class="text-gray-medium uppercase cursor-pointer" @click="cancelDelete">
          Keep current
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { stubFalse } from 'cypress/types/lodash'
import AudioPlayer from './AudioPlayer.vue'
import Recorder from './lib'
import { convertTimeMMSS } from './lib/utils'

export default {
  components: {
    AudioPlayer
    // Downloader,
    // IconButton,
    // Uploader
  },
  // mixins: [UploaderPropsMixin],
  props: {
    resetting: { type: Number, default: 0 },
    activeSlide: { type: Number, default: 0 },
    audioRecording: { type: Boolean, default: false },
    format: { type: String, default: 'wav' },
    attempts: { type: Number, default: 1 },
    time: { type: Number, default: 5 },

    bitRate: { type: Number, default: 128 },
    sampleRate: { type: Number, default: 44100 },

    showDownloadButton: { type: Boolean, default: true },
    showUploadButton: { type: Boolean, default: true }
  },
  emits: ['filechange', 'reset', 'deleteaudio', 'durationfail'],
  data () {
    return {
      isUploading: false,
      recorder: this._initRecorder(),
      recordList: [],
      selected: {} as any,
      uploadStatus: null,
      canceling: false,
      deletingAudio: false,
    }
  },
  computed: {
    attemptsLeft () {
      return this.attempts - this.recordList.length
    },
    iconButtonType () {
      return this.isRecording ? 'stop' : 'mic'
      // return this.isRecording && this.isPause ? 'mic' : this.isRecording ? 'pause' : 'mic'
    },
    isPause () {
      return this.recorder.isPause
    },
    isRecording () {
      return this.recorder.isRecording
    },
    recordedTime () {
      if (this.time && this.recorder.duration >= this.time * 60) {
        this.finishRecording()
      }
      return convertTimeMMSS(this.recorder.duration)
    },
    volume () {
      return parseFloat(this.recorder.volume)
    }
  },
  watch: {
    activeSlide (to, from) {
      if (to !== from) {
        if (this.isRecording) {
          this.stopRecorder()
          this.removeRecord(0)
        }
      }
    },
    resetting (to, from) {
      if (to !== from) {
        // Clear in the UI
        this.removeRecord(0)
      }
    }
  },
  beforeUnmount () {
    this.stopRecorder()
  },
  methods: {
    convertTimeMMSS,
    deleteAudio () {
      this.deletingAudio = true
    },
    cancelDelete () {
      this.deletingAudio = false
    },
    confirmDelete () {
      this.deleteRecord(0)
      this.deletingAudio = false
    },
    retry () {
      this.canceling = true
    },
    cancel () {
      this.canceling = false
    },
    confirm () {
      this.canceling = false
      this.startRecording()
    },
    startRecording () {
      this.$emit('reset', this.selected)
      this.removeRecord(0)
      //
      this.recorder.start()
    },
    finishRecording () {
      this.recorder.pause()
      // Immediately stop the recorder
      this.stopRecorder()
      // And choose the last made one for commit, if over 5 secs
      const latestRecord = this.recorder.records[0]
      if (latestRecord.durationSeconds > 5) {
        this.chooseRecord(latestRecord)

        this.$emit('filechange', this.selected)
      } else {
        log.trace('Removing recording under five seconds')
        this.removeRecord(0)
        this.$emit('durationfail', this.selected)
      }
    },
    toggleRecorder () {
      if (this.attempts && this.recorder.records.length >= this.attempts) {
        return
      }

      if (!this.isRecording || (this.isRecording && this.isPause)) {
        this.recorder.start()
      } else {
        this.recorder.pause()
        // Immediately stop the recorder
      }
    },
    stopRecorder () {
      if (!this.isRecording) {
        return
      }

      this.recorder.stop()
      this.recordList = this.recorder.recordList()
    },
    deleteRecord (idx: number) {
      if (this.recordList.length === 0) { return }

      this.recordList.splice(idx, 1)
      this.selected.url = null

      this.$emit('deleteaudio')
    },
    removeRecord (idx: number) {
      if (this.recordList.length === 0) { return }

      this.recordList.splice(idx, 1)
      this.selected.url = null

      this.$emit('reset')
    },
    chooseRecord (record: any) {
      if (this.selected === record) {
        return
      }
      this.selected = record
      // this.selectRecord && this.selectRecord(record)
    },
    _initRecorder () {
      return new Recorder({
        // beforeRecording: this.beforeRecording,
        // afterRecording: this.afterRecording,
        // pauseRecording: this.pauseRecording,
        // micFailed: this.micFailed,
        bitRate: this.bitRate,
        sampleRate: this.sampleRate,
        format: this.format
      })
    }
  }
}
</script>
