<template>
  <SlideBase :forward-disabled="!inputValid" forward :single-pane="true" back>
    <template #left>
      <div class="gap-y-6 flex-col md:flex-grow items-stretch flex justify-center">
        <div>
          <h1>Thank you for your story</h1>
          <p class="mt-2 font-monument text-lg leading-6 text-gray-dark">We also need to collect structured data about the
          sighting.</p>
        </div>

        <MadLib />
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2">
        <div class="beach-bg w-full h-full" />
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script setup lang="ts">

const { inputValid } = useBehavior()

</script>
