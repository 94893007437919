import { config } from 'vue-gtag'

export default function () {
  const { $sentrySetUser } = useNuxtApp()

  const visitorId = useFingerprint().fingerprint.value
  if (visitorId === null) { return }

  log.debug(`Setting visitor ID to ${visitorId}`)
  config({ visitor_id: 'G-5YSL623N57' })
  $sentrySetUser({ id: visitorId })
  log.debug('Sentry user and GA visitor ID set')
}
