<template>
  <div class="gap-y-5 flex-col flex">
    <slot />
  </div>
</template>

<script setup>
import { computed, provide, ref, useSlots } from 'vue'
import { optOut } from 'vue-gtag'

const slots = useSlots()
// Array of symbols to set unique id of each panel
// eslint-disable-next-line symbol-description
const idArray = slots.default().map((s) => Symbol(s.props.id))
// Holds the id of the currently expanded panel — default to first
const expandedArray = ref([idArray[0]])

// Use `provide` to communicate with the child panels
provide('accordion-register', () => {
  const id = idArray.shift()

  return {
    expanded: computed(() => expandedArray.value.includes(id) ),

    toggle () {
      if (expandedArray.value.includes(id)) {
        const index = expandedArray.value.indexOf(id)
        expandedArray.value.splice(index, 1)
      } else {
        if (id.toString() === 'Symbol(optout)') {
          // if opt out is open, no other panels should be
          expandedArray.value = [id]
        } else {
          // collapse opt out when opening either email or phone
          const optOutOpen = expandedArray.value.find(e => e.toString() === 'Symbol(optout)')
          if (optOutOpen) {
            expandedArray.value.splice(expandedArray.value.indexOf(optOutOpen, 1))
          }
          expandedArray.value.push(id)
        }
      }
    },

    unregister () {
      if (expandedArray.value.includes(id)) {
        expandedArray.value = []
      }
    }
  }
})
</script>
