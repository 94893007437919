/// <reference types="mapbox-gl" />

function placeTypeFromId(id: string) {
  return id.replace(/\..*$/, '')
}

const mapboxLocationTypes = ['poi', 'address', 'neighborhood', 'locality', 'postcode', 'place']

/* eslint-disable no-use-before-define */
// accept a mapboxgl.LngLat object and return a reverse geocode result with context
// https://docs.mapbox.com/api/search/#reverse-geocoding
async function reverseGeocode(lngLat: mapboxgl.LngLat) {
  const { public: ctx } = useRuntimeConfig()
  const response = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${ctx.mapboxToken}&types=${mapboxLocationTypes.join(',')}`
  )
  const json = await response.json()
  log.info(json)
  return json as ReverseGeocodeResult
}

export default function () {
  return {
    reverseGeocode,
    placeTypeFromId,
    mapboxLocationTypes
  }
}

export interface Properties {
  wikidata?: string
  short_code?: string
  accuracy?: string
  [key: string]: any
}

export interface ReverseGeocodeResult {
  type: string
  query: number[]
  features: Feature[]
  attribution: string
  [key: string]: any
}

export interface Feature {
  id: string
  type: string
  place_type: string[]
  relevance: number
  properties: Properties
  text: string
  place_name: string
  bbox?: number[]
  center: number[]
  geometry: Geometry
  context?: Context[]
  address?: string
  [key: string]: any
}

export interface Context {
  id: string
  text: string
  wikidata?: string
  short_code?: string
}

export interface Geometry {
  type: string
  coordinates: number[]
}
