<template>
  <SlideBase :forward="false" :forward-disabled="!hasLocation">
    <template #left>
      <div class="gap-y-5 flex-col flex-grow flex justify-around">
        <div class="flex flex-col gap-y-4 flex-grow w-full">
          <div class="flex-grow flex flex-col gap-y-4 justify-center">
            <h1>
              Where did this sighting occur?
            </h1>
            <p v-show="!hasLocation" class="font-monument text-lg leading-6 text-gray-dark">Don't remember the exact address? That's okay. Just put the town or area instead.</p>
            <div v-show="!hasLocation" class="flex h-36 flex-col justify-center gap-y-3">
              <div id="geocoder" class="border-b -mt-14 border-gray-dark w-full h-10 font-monument" />
              <ClientOnly>
                <div v-if="!isMobile" class="md:w-96">
                  <span class="text-xs font-monument text-gray-dark">or long-press to drop a pin on the map</span>
                </div>
              </ClientOnly>
            </div>
          </div>
          <div v-show="hasLocation" class="flex flex-col flex-grow mt-6">
            <div v-if="markerLocation?.lat && markerLocation.lng" class="gap-y-3 flex flex-grow flex-col text-left items-start">
              <div class="flex flex-col w-full md:w-3/4">
                <div :class="[{'mb-10': isMobile}, 'flex items-end relative']">
                  <div class="font-monument text-lg leading-6 border-0 border-b border-gray-black border w-full pb-2">
                    {{ selectedAddressText
                      ? selectedAddressText
                      : `[${markerLocation.lat.toFixed(3)}, ${markerLocation.lng.toFixed(3)}]`
                    }}
                  </div>
                  <div class="font-monument text-lg cursor-pointer text-space-orange leading-6 tracking-wide p-2 absolute right-0" data-test="Reset" @click="resetSearch">
                    Reset
                  </div>
                </div>
                <div v-if="!isMobile" class="mb-10">
                  <span class="text-sm text-not-so-black font-monument block py-2">Drag the marker on the map to select the location.</span>
                </div>
                <div v-if="yesOrNoPII === 'yes'" class="flex flex-col gap-y-2 text-lg leading-6 py-2 font-monument">
                  <p class="pb-4">
                    We have selected a higher zoom level of the address provided. Only the general area will be shown on our public map.
                  </p>
                </div>
              </div>
              <div
                v-if="geocodeResultOptions !== null && geocodeResultOptions.length !== 0 && yesOrNoPII === ''"
                class="flex flex-col gap-y-2 justify-start text-sm flex-grow w-full"
              >
                <LoadingSpinner v-if="geocodeResultOptions === null" class="flex-grow" />
                <!-- <div v-else-if="geocodeResultOptions.length === 0">
                  [[ UNABLE TO DETERMINE LOCATION ]]
                </div> -->
                <div
                  v-else-if="geocodeResultOptions.length > 0"
                  class="flex flex-col gap-y-5 overflow-y-auto text-gray-medium"
                >
                  <div class="text-not-so-black py-2 leading-5 md:w-3/4">
                    <span class="block text-lg leading-6 font-monument">
                      Is the address your home, office, school, or other personally identifiable location? If so, and if you would like to obscure it, click "YES" and we will hide the exact address for your privacy.
                    </span>
                    <InputRadioGroup v-model="yesOrNoPII" :options="optionsArray" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-sm flex mx-auto gap-x-6">
          <InputButton :disabled="hasPrecisionOptions || !inputValid" @click="nextSlide()">
            Next →
          </InputButton>
        </div>
      </div>
    </template>
    <template #right>
      <SlideRightContainer class="md:basis-1/2 flex-grow flex" style="min-height: 300px">
        <!-- <ClientOnly> -->
        <div id="mapbox-location-input" class="flex-grow" />
        <!-- </ClientOnly> -->
      </SlideRightContainer>
    </template>
  </SlideBase>
</template>
<script lang="ts" setup>
/// <reference types="mapbox-gl" />
import '~/assets/css/mapbox.css'
import log from 'consola'
import mapboxgl from 'mapbox-gl'
import type { Feature } from '~~/composables/use-geocode'

const segment = useSegment()
const { fingerprint } = useFingerprint()
const { geocodeSelection, markerLocation, geocodeId: selectedGeocodeId, reverseGeocodeResult, inputValid, yesOrNoPII, addressText: selectedAddressText } = useLocationSlide()
const { nextSlide } = useNavigation()
const { isMobile } = useDevice()
const { placeTypeFromId, reverseGeocode, mapboxLocationTypes } = useGeocode()
const hasLocation = computed(() => markerLocation.value !== null)
const hasPrecisionOptions = computed(() => yesOrNoPII.value === '' && geocodeResultOptions.value?.length !== 0)
const defaultCenter: mapboxgl.LngLatLike = { lng: -99, lat: 30 }
const defaultZoom = 0
const resultMode = useState<'reverse' | 'geocode' | null>('resultMode', () => null)
const optionsArray = ['no', 'yes']
let g: any
let map: mapboxgl.Map
let precisionFeatures: Feature[]
let selectedCenter: { lng: number, lat: number }

const ZOOM_DURATION = 4500

const geocodeResultOptions = computed(() => {
  let result = []
  switch (resultMode.value) {
    case 'reverse':
      result = reverseGeocodeResult.value?.features || []
      break
    case 'geocode':
      result = geocodeSelection.value?.context || []
      break
    default:
      return null
  }

  return result
    .filter((r: { id: string }) => {
      const type = placeTypeFromId(r.id)
      if (type === 'postcode' || type === 'locality') { return false }
      return mapboxLocationTypes.includes(placeTypeFromId(r.id))
    })
})

function setPIIPrecision() {
  const precisionLevels = geocodeResultOptions.value.map((option: { id: string}) => placeTypeFromId(option.id))
  const hasPrecisionFeatures = precisionFeatures.length > 0
  let selectedOption

  if (yesOrNoPII.value === 'yes') {
    if (precisionLevels.includes('neighborhood')) {
      selectedOption = hasPrecisionFeatures
        ? precisionFeatures.find((option: { id: string}) => option.id.includes('neighborhood'))
        : geocodeResultOptions.value.find((option: { id: string}) => option.id.includes('neighborhood'))
    } else {
      selectedOption = hasPrecisionFeatures
        ? precisionFeatures.find((option: { id: string}) => option.id.includes('place'))
        : geocodeResultOptions.value.find((option: { id: string}) => option.id.includes('place'))
    }
    selectedAddressText.value = selectedOption.text
    selectedPrecision(selectedOption.id, selectedOption.bbox)
    setTimeout(() => {
      drawRadius()
    }, ZOOM_DURATION)
  } else if (yesOrNoPII.value === 'no') {
    map.zoomTo(16, { duration: ZOOM_DURATION, essential: true })
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0)
  }
}

function drawRadius() {
  map.addSource('radius', {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [selectedCenter.lng, selectedCenter.lat]
      },
      properties: {
        title: 'PII Radius'
      }
    }
  })
  map.addLayer({
    id: 'radius',
    type: 'circle',
    source: 'radius', // reference the data source
    layout: {},
    paint: {
      'circle-color': '#FF5935', // space orange fill
      'circle-opacity': 0.5,
      'circle-radius': 125
    }
  })
}

function selectedPrecision(id: string | number, bbox: any) {
  if (typeof id === 'number') {
    id = id.toString()
  }
  selectedGeocodeId.value = id
  if (bbox) {
    map.fitBounds(bbox, { padding: 50, duration: ZOOM_DURATION, essential: true })
    return
  }

  const precisionLevel = placeTypeFromId(id)
  switch (precisionLevel) {
    case 'precise':
      map.zoomTo(16, { duration: ZOOM_DURATION, essential: true })
      break
    case 'neighborhood':
      map.zoomTo(14, { duration: ZOOM_DURATION, essential: true })
      break
    case 'locality':
      map.zoomTo(12, { duration: ZOOM_DURATION, essential: true })
      break
    case 'place':
      map.zoomTo(11, { duration: ZOOM_DURATION, essential: true })
      break
    case 'region':
      map.zoomTo(6.5, { duration: ZOOM_DURATION, essential: true })
      break
    case 'postcode':
      map.zoomTo(14, { duration: ZOOM_DURATION, essential: true })
      break
    case 'district':
      map.zoomTo(8, { duration: ZOOM_DURATION, essential: true })
      break
    case 'country':
      map.zoomTo(4, { duration: ZOOM_DURATION, essential: true })
      break
    default: {
      log.warn('Unknown precision level', precisionLevel)
      break
    }
  }
}

watch(markerLocation, (newLocation) => {
  if (newLocation === null) {
    return
  }
  updateReverseGeocodeResult(newLocation)
  segment.track('submission_location_search_input_updated', {
    address: selectedAddressText.value || newLocation,
    locationCoordinate: newLocation
  }, {
      traits: {
        visitorId: fingerprint.value
      }
    })
})

watch(yesOrNoPII, setPIIPrecision)

onActivated(() => {
  if (map) {
    map.resize()
    log.debug('Resizing map')
  }
})

onMounted(async () => {
  useResizeObserver(document.body, () => {
    if (map) {
      map.resize()
      log.debug('Resizing map')
    }
  })

  const mapboxgl = await import('mapbox-gl').then(m => m.default || m)
  const MapboxGeocoder = await import('@mapbox/mapbox-gl-geocoder').then(m => m.default || m)
  const geocoder = new MapboxGeocoder({
    accessToken: 'pk.eyJ1IjoiZW5pZ21hbGFicyIsImEiOiJja2prMzZ6NHA1YnppMnpucHIzdnc3d255In0.jPuo0Fn7BhDGGtlIQ1LotQ',
    placeholder: 'Enter Location',
    proximity: 'ip' as any,
    types: mapboxLocationTypes.join(','),
    enableEventLogging: false,
    mapboxgl,
    flyTo: false,
    marker: false,
    enableGeolocation: true,
    clearAndBlurOnEsc: true
  })
  g = geocoder

  document.getElementById('geocoder')?.appendChild(geocoder.onAdd(map))
  // create a reusable marker to annotate result on map
  const marker = new mapboxgl.Marker({
    draggable: true
  })

  map = new mapboxgl.Map({
    accessToken: 'pk.eyJ1IjoiZW5pZ21hbGFicyIsImEiOiJja2prMzZ6NHA1YnppMnpucHIzdnc3d255In0.jPuo0Fn7BhDGGtlIQ1LotQ',
    localFontFamily: 'OCRF-Light',
    trackResize: true,
    container: 'mapbox-location-input',
    style: 'mapbox://styles/enigmalabs/cluu8ulue004901omh98k39lm',
    center: defaultCenter,
    zoom: defaultZoom,
    bearing: 0,
    interactive: !isMobile,
  })

  map.on('load', ()  => {
    map.setLayoutProperty('users-cluster', 'visibility', 'none')
    map.setLayoutProperty('flagship-incident', 'visibility', 'none')
    map.setLayoutProperty('historical-continental', 'visibility', 'none')
    map.setLayoutProperty('historical-cluster', 'visibility', 'none')
  })

  map.once('idle', (_) => {
    let mousedownTime = 0
    flyTo(defaultCenter, defaultZoom)

    map.on(('mousedown'), (_) => {
      mousedownTime = Date.now()
    })
    map.on(('dragstart'), (_) => { mousedownTime = 0 })

    map.on('mouseup', (e) => {
      if (Date.now() - mousedownTime > 1000 &&
        geocodeSelection.value === null &&
        markerLocation.value === null) {
        const longPressLocation = e.lngLat
        resultMode.value = 'reverse'
        flyTo(longPressLocation)
        setMarker(longPressLocation)
      }
    })
  })

  function flyTo(lngLat: mapboxgl.LngLatLike, zoom = 12) {
    const center = mapboxgl.LngLat.convert(lngLat)
    // fly to target after 250ms
    setTimeout(() => {
      map.flyTo({ center, zoom, duration: 5000, essential: true })
    }, 250)
  }

  marker.on('dragend', () => {
    markerLocation.value = marker.getLngLat()
    yesOrNoPII.value = ''
    selectedAddressText.value = `[${markerLocation.value.lat.toFixed(3)}, ${markerLocation.value.lng.toFixed(3)}]`
    resultMode.value = 'reverse'
    reverseGeocodeResult.value = null

    flyTo(markerLocation.value)
  })

  // reset map, fly to default location, and remove marker on clear
  geocoder.on('clear', () => {
    reset()
  })

  geocoder.on('result', ({ result }) => {
    geocodeSelection.value = result
    resultMode.value = 'geocode'
    selectedAddressText.value = result.text
    selectedGeocodeId.value = result.id
    const lngLat = mapboxgl.LngLat.convert(result.center)
    selectedCenter = lngLat
    setMarker(lngLat)
    map
      .flyTo({ center: result.center, zoom: 16, duration: 2000, essential: true })
  })

  function reset() {
    if (yesOrNoPII.value === 'yes') {
      map.removeLayer('radius')
      map.removeSource('radius')
    }
    marker.remove()
    markerLocation.value = null
    geocodeSelection.value = null
    selectedGeocodeId.value = null
    reverseGeocodeResult.value = null

    map
      .setMaxBounds()
    flyTo(defaultCenter, defaultZoom)
  }

  function setMarker(location: mapboxgl.LngLat) {
    markerLocation.value = location
    marker.remove()
    marker
      .setLngLat(location)
      .addTo(map)
  }
})

function resetSearch() {
  g.clear()
  yesOrNoPII.value = ''
  geocodeSelection.value = null
  markerLocation.value = null
}

async function updateReverseGeocodeResult(location: mapboxgl.LngLat) {
  reverseGeocodeResult.value = null
  const result = await reverseGeocode(location)
  reverseGeocodeResult.value = result
  precisionFeatures = result.features
  selectedGeocodeId.value = result.features[0]?.id
}

</script>
