/// <reference types="mapbox-gl" />
import { TimezoneResponse } from './slides/use-date-time-slide'

const tzForLocation = async function (location: mapboxgl.LngLat, timestamp: Date = new Date()) {
  log.info(`Requesting tz for ${location} at ${timestamp}`)
  const config = useRuntimeConfig()
  const { lat, lng } = location

  let url = 'https://maps.googleapis.com/maps/api/timezone/json?location=' +
    lat + '%2C' + lng +
    '&key=' + config.public.googleLocationApiKey

  url += '&timestamp=' + timestamp.getMilliseconds() / 1000
  const data = await $fetch<TimezoneResponse>(url, { method: 'GET' })
  log.info(`Got tz response: ${JSON.stringify(data)}`)
  return data
}

export {
  tzForLocation
}
